import { css } from "@emotion/core"

export const globalFonts = css`
    @font-face {
        font-family: CormorantLight;
        font-display: block;
        src: url("/fonts/cormorant/Cormorant-Light.woff2") format("woff2"),
            url("/fonts/cormorant/Cormorant-Light.woff") format("woff"),
            url("/fonts/cormorant/Cormorant-Light.eot") format("eot"),
            url("/fonts/cormorant/Cormorant-Light.otf") format("otf"),
            url("/fonts/cormorant/Cormorant-Light.ttf") format("ttf");
    }
    @font-face {
        font-family: ProximaNovaRegular;
        font-display: block;
        src: url("/fonts/proximaNova/proxima_nova_regular.woff2")
                format("woff2"),
            url("/fonts/proximaNova/proxima_nova_regular.woff") format("woff"),
            url("/fonts/proximaNova/proxima_nova_regular.eot") format("eot"),
            url("/fonts/proximaNova/proxima_nova_regular.otf") format("otf"),
            url("/fonts/proximaNova/proxima_nova_regular.ttf") format("ttf");
    }
    @font-face {
        font-family: NeutrafaceTextLight;
        font-display: block;
        src: url("/fonts/neutraface/NeutrafaceText-Light.woff2") format("woff2"),
            url("/fonts/neutraface/NeutrafaceText-Light.woff") format("woff"),
            url("/fonts/neutraface/NeutrafaceText-Light.eot") format("eot"),
            url("/fonts/neutraface/NeutrafaceText-Light.otf") format("otf"),
            url("/fonts/neutraface/NeutrafaceText-Light.ttf") format("ttf");
    }
`
