import React from "react"
import PropTypes from "prop-types"
import styled from "@emotion/styled"
import { css } from "@emotion/core"
import { spacer } from "../../styles/sizes"

const Spacer = ({ children, size }) => {
    return <SpacerContainer size={size}>{children}</SpacerContainer>
}

Spacer.propTypes = {
    children: PropTypes.node,
    size: PropTypes.oneOf([
        "headerMarginTop",
        "xxsmall",
        "xsmall",
        "small",
        "medium",
        "large"
    ])
}

export default Spacer

const SpacerContainer = styled.div`
    ${props =>
        props.size === "headerMarginTop" &&
        css`
            margin-top: ${spacer.small};
        `}
    ${props =>
        props.size === "xxsmall" &&
        css`
            margin-bottom: ${spacer.xxsmall};
        `}
    ${props =>
        props.size === "xsmall" &&
        css`
            margin-bottom: ${spacer.xsmall};
        `}
    ${props =>
        props.size === "small" &&
        css`
            margin-bottom: ${spacer.small};
        `}
    ${props =>
        props.size === "medium" &&
        css`
            margin-bottom: ${spacer.medium};
        `}
    ${props =>
        props.size === "large" &&
        css`
            margin-bottom: ${spacer.large};
        `}
`
