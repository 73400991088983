import React from "react"
import styled from "@emotion/styled"
import { colors } from "../../styles/colors"
import { fontFamily } from "../../styles/fonts/fontFamily"
import { useStaticQuery, graphql } from "gatsby"
import { get } from "lodash"
import { maxW } from "../../styles/mediaQueries"
import { css } from "@emotion/core"

const ContactInfo = ({ footer, contactPage }) => {
    const footerQuery = useStaticQuery(
        graphql`
            query {
                contentfulContactPage {
                    clubEmail
                    clubPhoneNumber
                    memberConciergePhoneNumber
                }
            }
        `
    )

    const {
        clubEmail = "",
        clubPhoneNumber = "",
        memberConciergePhoneNumber = ""
    } = get(footerQuery, "contentfulContactPage", {})

    return (
        <InfoHolder footer={footer} contactPage={contactPage}>
            <ContactContainer footer={footer}>
                <Contact footer={footer}>
                    <span>CLUB: </span>
                    <a href={`tel:${clubPhoneNumber}`}>{clubPhoneNumber}</a>
                    <Dot footer={footer} contactPage={contactPage}>
                        •
                    </Dot>
                </Contact>
                <Contact footer={footer}>
                    <span>MEMBER CONCIERGE: </span>
                    <a href={`tel:${memberConciergePhoneNumber}`}>
                        {memberConciergePhoneNumber}
                    </a>
                </Contact>
            </ContactContainer>
            <a id="email" href={`mailto:${clubEmail}`}>
                {clubEmail.toUpperCase()}
            </a>
        </InfoHolder>
    )
}

export default ContactInfo

const InfoHolder = styled.div`
    display: flex;
    margin: 0 6%;
    flex-direction: column;
    align-self: center;
    align-items: center;
    span,
    a {
        font-family: ${fontFamily.accent};
        color: ${colors.eastBay};
        font-size: 15.5px;
        letter-spacing: 2.58px;
        line-height: 1.81;
    }
    ${({ footer, contactPage }) => css`
        @media only screen and (max-width: 814px) {
            span,
            a {
                font-size: ${footer && `14px`};
            }
        }
        ${maxW[1]} {
            span,
            a {
                font-size: ${footer && `12px`};
            }
        }
        @media only screen and (max-width: 669px) {
            margin-left: ${footer && `auto`};
            margin-top: ${footer && `20px`};
            #email {
                margin-right: ${footer && `auto`};
            }
            align-self: ${footer && `unset`};
            align-items: ${footer && `unset`};
            margin: ${footer && `20px 0 0 0`};
        }
        ${maxW[0]} {
            span,
            a {
                font-size: ${contactPage && `12px`};
            }
        }
        @media only screen and (max-width: 320px) {
            span,
            a {
                font-size: ${footer && `10px`};
            }
        }
    `}
`
const ContactContainer = styled.div`
    ${({ footer }) => css`
        min-width: ${footer && `460px`};
        @media only screen and (max-width: 669px) {
            min-width: ${footer && "unset"};
            max-width: ${footer && "300px"};
        }
    `}
`
const Contact = styled.div`
    display: inline-block;
    ${({ footer }) => css`
        @media only screen and (max-width: 669px) {
            margin-bottom: ${footer && `10px`};
        }
    `}
`
const Dot = styled.span`
    margin: 0 20px;
    ${({ footer, contactPage }) => css`
        ${maxW[1]} {
            margin: ${footer && `0 10px`};
        }
        @media only screen and (max-width: 669px) {
            display: ${footer && `none`};
            display: ${contactPage && `none`};
        }
    `}
`
