import React from "react"
import styled from "@emotion/styled"
import { colors } from "../../styles/colors"
import { fontFamily } from "../../styles/fonts/fontFamily"
import ReactHtmlParser from "react-html-parser"
import { css } from "@emotion/core"
import { Link } from "gatsby"
import Spacer from "../../components/Partials/Spacer"
import { fluid } from "../../styles/mixins"
import { breakpoints } from "../../styles/sizes"
import { maxW } from "../../styles/mediaQueries"

export const HeaderText = ({ copy, isH1, children, mTop, smallH2 }) => {
    const CopyWrapper = isH1 ? H1asHeader : smallH2 ? H2asSubheader : H2asHeader

    return (
        <>
            <Spacer size="xsmall" />
            <HeaderTextContainer className={`header-text-container`}>
                <Spacer size={(isH1 || mTop) && "headerMarginTop"} />
                <CopyWrapper>{copy}</CopyWrapper>
                <ShortLine />
                <Spacer size="xsmall" />
                {children}
            </HeaderTextContainer>
        </>
    )
}

export const BodyText = ({
    copy,
    padding,
    noMarginTop,
    marginTopAndBottom,
    extraLineheight
}) => {
    return (
        <BodyTextContainer
            padding={padding}
            noMarginTop={noMarginTop}
            marginTopAndBottom={marginTopAndBottom}
        >
            <BodyTextWrapper extraLineheight={extraLineheight}>
                {ReactHtmlParser(copy)}
            </BodyTextWrapper>
        </BodyTextContainer>
    )
}

export const FormInstructions = styled.p`
    font-family: ${fontFamily.content};
    line-height: 1.2;
    ${fluid("font-size", 18, 20, breakpoints[1], breakpoints[3])}
    text-align: center;
    padding-top: 20px;
    max-width: 600px;
    align-self: center;
    ${maxW[1]} {
        padding: 0 20px;
    }
`

export const JobPosition = styled.h3`
    font-family: ${fontFamily.accent};
    ${fluid("font-size", 18, 20, breakpoints[1], breakpoints[3])}
    letter-spacing: 4.44px;
    font-weight: bold;
    color: ${colors.eastBay};
`

export const CarouselP = styled.p`
    color: ${colors.white};
    font-family: ${fontFamily.accent};
    text-align: center;
    margin: auto;
    padding: 50px 60px;
    line-height: 1.8;
    letter-spacing: 4.44px;
    font-size: 20px;
    align-self: center;
    ${maxW[1]} {
        font-size: 18px;
        color: ${colors.black};
        line-height: 1.5;
        letter-spacing: 2px;
        padding: 10px;
    }
`

const UnderlinedTextCTAStyle = component => styled(component)`
    font-family: ${fontFamily.accent};
    ${fluid("font-size", 12, 16, breakpoints[0], breakpoints[3])};
    color: ${colors.eastBay};
    letter-spacing: 3.11px;
    border-bottom: 1px solid ${colors.eastBay};
    padding-bottom: 5px;
    ${({ zIndex }) => css`
        z-index: ${zIndex};
    `}
    ${maxW[0]} {
        line-height: 35px;
    }
`

export const UnderlinedTextCTA = UnderlinedTextCTAStyle(Link)
export const UnderlinedTextCTAExternal = UnderlinedTextCTAStyle("a")

export const AfterSubmissionHeader = styled.h3`
    font-family: ${fontFamily.header};
    ${fluid("font-size", 38, 50, breakpoints[1], breakpoints[3])};
    color: ${colors.black};
`

export const WhenEventParagraph = styled.p`
    font-family: ${fontFamily.accent};
    line-height: 1.81;
    ${fluid("font-size", 16, 18, breakpoints[1], breakpoints[3])};
    letter-spacing: 2.58px;
    color: ${colors.black};
`
export const ToogleEventInfo = styled.p`
    font-family: ${fontFamily.accent};
    ${fluid("font-size", 16, 18, breakpoints[1], breakpoints[3])};
    color: ${colors.blueChill};
    text-decoration: underline;
    cursor: pointer;
    display: inline-block;
`

export const NotFoundPageHeader = styled.h2`
    font-family: ${fontFamily.header};
    color: ${colors.black};
    ${fluid("font-size", 42, 60, breakpoints[1], breakpoints[3])};
`

const BodyTextContainer = styled.div`
    display: inline-block;
    ${({ padding, noMarginTop, marginTopAndBottom }) =>
        css`
            padding: ${padding && `0 94px`};
            margin: ${noMarginTop && `10px 0 40px 0`};
            margin: ${marginTopAndBottom && `70px 0 70px 0`};
            ${maxW[3]} {
                padding: ${padding && `0 25px`};
            }
        `};
`

const HeaderTextContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
`

const ShortLine = styled.div`
    border: solid 1px ${colors.eastBay};
    width: 77px;
    margin-top: 20px;
`

const HeaderStyle = tag => styled(tag)`
    font-family: ${fontFamily.header};
    ${fluid("font-size", 60, 90, breakpoints[1], breakpoints[3])};
    color: ${colors.black};
    width: 100%;
`

const SmallHeaderStyle = tag => styled(tag)`
    font-family: ${fontFamily.header};
    ${fluid("font-size", 42, 60, breakpoints[1], breakpoints[3])};
    color: ${colors.black};
    width: 100%;
`

const H1asHeader = HeaderStyle("h1")
const H2asHeader = HeaderStyle("h2")
const H2asSubheader = SmallHeaderStyle("h2")

const BodyTextWrapper = styled.div`
    font-family: ${fontFamily.content};
    color: ${colors.black};
    ${fluid("font-size", 18, 20, breakpoints[1], breakpoints[3])};
    line-height: 1.4;
    ${({ extraLineheight }) => css`
        line-height: ${extraLineheight && `2.2`};
    `}
    em {
        font-style: italic;
    }
    strong {
        font-weight: bold;
    }
    ul {
        list-style: inside;
    }
    h1 {
        font-size: 50px;
        ${fluid("font-size", 42, 50, breakpoints[1], breakpoints[3])};
    }
    h2 {
        font-size: 40px;
        ${fluid("font-size", 32, 40, breakpoints[1], breakpoints[3])};
    }
    h3 {
        font-size: 30px;
        ${fluid("font-size", 22, 30, breakpoints[1], breakpoints[3])};
    }
`
