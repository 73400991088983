/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import { Global } from "@emotion/core"
import { globalFonts } from "../styles/fonts/fontFamilyDefinition"

import Header from "./Partials/header"
import Footer from "./Partials/Footer"

import "./reset.css"
import { MaxWidthOuterContainer } from "../styles/globalCss"

import { Controller, Scene } from "react-scrollmagic"

export const MyContext = React.createContext()

const Layout = ({ children }) => {
    const data = useStaticQuery(graphql`
        query {
            placeholderImage: file(
                relativePath: { eq: "la-coquille-logo.png" }
            ) {
                childImageSharp {
                    fluid(maxWidth: 116) {
                        ...GatsbyImageSharpFluid
                    }
                }
            }
        }
    `)

    return (
        <>
            <Global styles={globalFonts} />
            <MyContext.Provider value={{}}>
                <MaxWidthOuterContainer>
                    <Controller>
                        <Scene
                            indicators={false}
                            triggerHook="onLeave"
                            triggerElement="#main"
                            classToggle={["#mobile-nav", "background-on"]}
                            reverse={true}
                            offset={-100}
                        >
                            <Header
                                logoImg={
                                    data.placeholderImage.childImageSharp.fluid
                                }
                            />
                        </Scene>
                    </Controller>
                    <main id="main">{children}</main>
                    <Footer
                        logoImg={data.placeholderImage.childImageSharp.fluid}
                    />
                </MaxWidthOuterContainer>
            </MyContext.Provider>
        </>
    )
}

Layout.propTypes = {
    children: PropTypes.node.isRequired
}

export default Layout
