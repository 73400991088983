import styled from "@emotion/styled"
import { fontFamily } from "./fonts/fontFamily"
import { colors } from "./colors"
import { css } from "@emotion/core"
import { maxW } from "./mediaQueries"
import { fluid } from "./mixins"
import { breakpoints } from "./sizes"

export const MaxWidthInnerContainer = styled.div`
    max-width: 1010px;
    text-align: center;
    margin: auto;
    background-color: ${colors.white};
    ${({ transparent }) =>
        css`
            background: ${transparent && `none`};
        `}
    ${maxW[2]} {
        width: 85%;
    }
    ${maxW[1]} {
        width: 90%;
    }
    ${maxW[0]} {
        width: 93%;
    }
`

export const MaxWidthOuterContainer = styled.div`
    max-width: 1440px;
    margin: auto;
    position: relative;
`

export const Button = styled.button`
    background-color: ${colors.eastBay};
    color: ${colors.white};
    font-family: ${fontFamily.accent};
    font-size: 16px;
    letter-spacing: 3.56px;
    border: 0;
    padding: 17px 42px;
    margin: auto;
    :hover {
        cursor: pointer;
    }
    :disabled,
    &.not-ready {
        cursor: not-allowed;
        opacity: 0.5;
    }
`

export const BreakLine = styled.div`
    border: 1.5px solid #f0f0f0;
    ${fluid("margin-left", 40, 150, breakpoints[0], breakpoints[3])}
    ${fluid("margin-right", 40, 150, breakpoints[0], breakpoints[3])}
`
