import React from "react"
import styled from "@emotion/styled"
import { colors } from "../../styles/colors"
import { Link } from "gatsby"
import ContactInfo from "./ContactInfo"
import Img from "gatsby-image"
import { maxW } from "../../styles/mediaQueries"

const Footer = ({ logoImg }) => {
    return (
        <FooterTag>
            <FooterContainer>
                <Link to="/">
                    <LogoContainer>
                        <Img fluid={logoImg} alt={"La Coquille Logo"} />
                    </LogoContainer>
                </Link>
                <ContactInfo footer />
            </FooterContainer>
        </FooterTag>
    )
}

export default Footer

const LogoContainer = styled.div`
    width: 116px;
    height: 146px;
    ${maxW[0]} {
        padding: unset;
        width: 86px;
        height: 110px;
    }
`
const FooterTag = styled.footer`
    background-color: ${colors.mischkaOpacity};
`
const FooterContainer = styled.div`
    display: flex;
    justify-content: center;
    padding: 30px 20px;
    @media only screen and (max-width: 669px) {
        flex-direction: column;
    }
`
