import { minW, maxW } from "../styles/mediaQueries"

export const fluid = (
    property,
    minSize,
    maxSize,
    minScreenWidth,
    maxScreenWidth
) => {
    return `
        ${property}: calc( ${minSize}px + (${maxSize -
        minSize}) * ((100vw - ${minScreenWidth}px) / (${maxScreenWidth -
        minScreenWidth})) );
        @media screen and (min-width:${maxScreenWidth}px) {
            ${property}: ${maxSize}px;
        }
        @media screen and (max-width:${minScreenWidth}px) {
            ${property}: ${minSize}px;
        }
    `
}

export const desktopOnly = displayStyle => {
    return `
        display: ${displayStyle ? displayStyle : "none"};
        ${minW[0]} {
            display: block;
        }
    `
}

export const mobileOnly = displayStyle => {
    return `
        display: ${displayStyle ? displayStyle : "block"};
        ${maxW[0]} {
            display: none;
        }
    `
}
